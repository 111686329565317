import { GetterTree } from 'vuex'
import { ImageState, ImageObj } from './types'
import { RootState } from '../types'

export const getters: GetterTree<ImageState, RootState> = {
  getImagesData (state): Array<ImageObj> {
    return state.data
  },
  getImagesLoaded (state): boolean {
    return state.loaded
  },
  getImagesMessage (state): string | null {
    return state.message
  },
  getImagesQueries (state): Array<string> {
    return state.queries
  },
  getImagesQueriesLoaded (state): boolean {
    return state.queriesLoaded
  },
  getImagesQueriesMessage (state): string | null {
    return state.queriesMessage
  }
}
