import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a40184a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-content" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "lede-text" }
const _hoisted_5 = { class: "card-footer" }
const _hoisted_6 = { class: "card-controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrayJoin = _resolveComponent("ArrayJoin")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.itemClass),
    tabindex: "0"
  }, [
    _createVNode(_component_router_link, {
      to: {
      name: 'Story',
      params: {'storyTitle': _ctx.item.title, slug: this.$route.params.slug}}
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.item.title), 1),
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.item.ledeText), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                class: _normalizeClass('favorite ' + _ctx.buttonClass ),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.favoriteClick(_ctx.item.id)))
              }, null, 2)
            ]),
            _createElementVNode("ul", null, [
              (_ctx.item.linkedParticipantsNames)
                ? (_openBlock(), _createBlock(_component_ArrayJoin, {
                    key: 0,
                    joinedProp: 'Participants',
                    namesArray: _ctx.item.linkedParticipantsNames,
                    display: 'basic'
                  }, null, 8, ["namesArray"]))
                : _createCommentVNode("", true),
              (_ctx.item.programsName)
                ? (_openBlock(), _createBlock(_component_ArrayJoin, {
                    key: 1,
                    joinedProp: 'Programs',
                    namesArray: _ctx.item.programsName,
                    display: 'basic'
                  }, null, 8, ["namesArray"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("ul", null, [
              (_ctx.item.linkedTagsNames)
                ? (_openBlock(), _createBlock(_component_ArrayJoin, {
                    key: 0,
                    joinedProp: 'Tags',
                    namesArray: _ctx.item.linkedTagsNames,
                    display: 'basic'
                  }, null, 8, ["namesArray"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ], 2))
}