
import { defineComponent, watchEffect, computed, ref } from 'vue'
import { extendRefernceItem, setItemClass, targetRoute } from '@/composables/referenceFunctions'
import ArrayJoin from '@/components/ArrayJoin.vue'
import { ImageObj } from '@/store/images/types'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'imageCard',
  props: {
    imageProp: Object
  },
  components: {
    ArrayJoin
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const itemClass = ref('clipped')
    const isFavorite = ref(false)
    const image = computed(() => props.imageProp as unknown as ImageObj)
    const favorites = computed(() => store.getters.getFavoritesImages as Array<string>)
    const buttonClass = computed(() => isFavorite.value ? 'selected' : '')

    watchEffect(() => {
      itemClass.value = setItemClass(props.imageProp?.imageId, route)
      if (favorites.value) {
        isFavorite.value = favorites.value.indexOf(props.imageProp?.imageId) >= 0
      }
    })
    const onClick = (itemId:string) => {
      extendRefernceItem(itemId, route)
      router.push(targetRoute.value)
    }
    const favoriteClick = (itemId:string) => {
      if (!isFavorite.value) {
        store.dispatch('setFavorite', {
          type: 'image',
          id: itemId,
          list: [itemId, ...favorites.value]
        })
      } else {
        const newArr = favorites.value.filter((item:string) => item !== itemId)
        store.dispatch('setFavorite', {
          type: 'image',
          id: itemId,
          list: newArr
        })
      }
    }
    return {
      image,
      itemClass,
      favorites,
      isFavorite,
      buttonClass,
      onClick,
      favoriteClick
    }
  }
})
