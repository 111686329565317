import { GetterTree } from 'vuex'
import { StoryState, StoryObj } from './types'
import { RootState } from '../types'

export const getters: GetterTree<StoryState, RootState> = {
  getStoriesRelatedData (state): Array<StoryObj> {
    return state.relatedData
  },
  getStoriesRelatedLoaded (state): boolean {
    return state.relatedLoaded
  },
  getStoriesRelatedMessage (state): string | null {
    return state.relatedMessage
  },
  getStoriesQueries (state): Array<string> {
    return state.queries
  },
  getStoriesQueriesLoaded (state): boolean {
    return state.queriesLoaded
  },
  getStoriesQueriesMessage (state): string | null {
    return state.queriesMessage
  },
  getStoriesQueryData (state): Array<StoryObj> {
    return state.queryData
  },
  getStoriesQueryLoaded (state): boolean {
    return state.queryDataLoaded
  },
  getStoriesFeaturedData (state): Array<StoryObj> {
    return state.featuredData
  },
  getStoriesFeaturedLoaded (state): boolean {
    return state.featuredLoaded
  }
}
