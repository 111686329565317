import { GetterTree } from 'vuex'
import { QuoteState, QuoteObj } from './types'
import { RootState } from '../types'

export const getters: GetterTree<QuoteState, RootState> = {
  getQuotesQueryData (state): Array<QuoteObj> {
    return state.queryData
  },
  getQuotesQueryLoaded (state): boolean {
    return state.queryDataLoaded
  },
  getQuotesQueries (state): Array<string> {
    return state.queries
  },
  getQuotesQueriesLoaded (state): boolean {
    return state.queriesLoaded
  },
  getQuotesQueriesMessage (state): string | null {
    return state.queriesMessage
  }
}
