
import { defineComponent } from 'vue'
import AccountControls from '@/components/AccountControls.vue'
import FilterNav from '@/components/FilterNav.vue'

export default defineComponent({
  name: 'Nav',
  components: {
    AccountControls,
    FilterNav
  },
  data () {
    return {
      searchActive: false,
      favoritesActive: false,
      isSticky: false
    }
  },
  mounted () {
    if (this.$route.name === 'Search') {
      this.searchActive = true
    } if (this.$route.name === 'Favorites') {
      this.favoritesActive = true
    }
  },
  computed: {
    slug () {
      return this.$route.params.slug
    }
  },
  watch: {
    $route (to) {
      if (to.name === 'Search') {
        this.searchActive = true
      } if (to.name !== 'Search') {
        this.searchActive = false
      } if (to.name === 'Favorites') {
        this.favoritesActive = true
      } if (to.name !== 'Favorites') {
        this.favoritesActive = false
      }
    }
  },
  methods: {
    headerClass () {
      if (this.searchActive && this.favoritesActive) {
        return 'searchActive favoritesActive'
      } if (!this.searchActive && this.favoritesActive) {
        return 'favoritesActive'
      } if (this.searchActive && !this.favoritesActive) {
        return 'searchActive'
      } else {
        return ''
      }
    },
    toggleSearch () {
      this.searchActive = !this.searchActive
      this.favoritesActive = false
    },
    toggleFavorites () {
      this.favoritesActive = true
      this.searchActive = false
    }
  }
})
