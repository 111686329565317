import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03acb770"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "card-content" }
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = {
  key: 0,
  class: "img flex-left"
}
const _hoisted_5 = { class: "card-footer" }
const _hoisted_6 = { class: "card-controls" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrayJoin = _resolveComponent("ArrayJoin")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.itemClass),
    tabindex: "0"
  }, [
    _createElementVNode("a", {
      class: "anchor",
      id: _ctx.image?.imageId
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("figure", _hoisted_3, [
        (_ctx.image.attachments)
          ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_4, null, 512)), [
              [_directive_lazy, {src: _ctx.image.attachments[0].thumbnails.large.url}]
            ])
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.image.description)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.image?.description), 1))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: _normalizeClass('favorite ' + _ctx.buttonClass ),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.favoriteClick(_ctx.image?.imageId)))
          }, null, 2)
        ]),
        (_ctx.image.linkedParticipantsNames || _ctx.image.programsName)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
              (_ctx.image.linkedParticipantsNames)
                ? (_openBlock(), _createBlock(_component_ArrayJoin, {
                    key: 0,
                    joinedProp: 'Participants',
                    namesArray: _ctx.image.linkedParticipantsNames,
                    display: 'basic'
                  }, null, 8, ["namesArray"]))
                : _createCommentVNode("", true),
              (_ctx.image.programsName)
                ? (_openBlock(), _createBlock(_component_ArrayJoin, {
                    key: 1,
                    joinedProp: 'Programs',
                    namesArray: _ctx.image.programsName,
                    display: 'basic'
                  }, null, 8, ["namesArray"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.image.linkedTags)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
              (_ctx.image.linkedTags)
                ? (_openBlock(), _createBlock(_component_ArrayJoin, {
                    key: 0,
                    joinedProp: 'Tags',
                    namesArray: _ctx.image.linkedTagsNames,
                    display: 'basic'
                  }, null, 8, ["namesArray"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}