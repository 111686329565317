import { ActionTree } from 'vuex'
import { RootState } from '../types'
import { StoryState } from './types'
import Airtable from 'airtable'

const base = new Airtable({
  apiKey: process.env.VUE_APP_AIRTABLE_API_KEY
})

const fieldsConfig = [
  'type',
  'id',
  'title',
  'status',
  'publicationDate',
  'ledeText',
  'bodyText',
  'linkedImages',
  'linkedImagesAttachments',
  'linkedQuotes',
  'linkedQuotesText',
  'relatedStories',
  'linkedTags',
  'linkedTagsNames',
  'linkedParticipants',
  'linkedParticipantsNames',
  'programsName',
  'createdDate'
]

const listConfig = [
  'type',
  'id',
  'title',
  'status',
  'ledeText',
  'linkedTags',
  'linkedTagsNames',
  'linkedImages',
  'linkedImagesAttachments',
  'linkedParticipantsNames',
  'placement'
]

const selectConfig = {
  view: 'Published',
  fields: fieldsConfig
}

const subsetConfig = {
  view: 'Published',
  fields: listConfig
}

const queryConfig = {
  view: 'Published',
  fields: ['title']
}

export const actions: ActionTree<StoryState, RootState> = {
  async loadStoriesData ({ commit, dispatch }, selectObj) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase('Stories').select(selectObj).eachPage(function page (records, fetchNextPage: () => void) {
      const filertedRecords = records.map(function (record) {
        return record.fields
      })
      if (selectObj.filterByFormula) {
        if (selectObj.store) {
          commit('SET_STORIES_FEATURED_DATA', filertedRecords)
          commit('SET_STORIES_FEATURED_LOADED', true)
        } else {
          commit('SET_STORIES_QUERY_DATA', filertedRecords)
          commit('SET_STORIES_QUERY_DATA_LOADED', true)
        }
      }
      fetchNextPage()
    }, function done (err: string) {
      if (err) {
        commit('SET_STORIES_LOADED', false)
        commit('SET_STORIES_MESSAGE', err)
      }
    })
  },
  getStoriesData ({ dispatch }, requestObj) {
    let selectObj: Record<string, unknown>
    switch (requestObj.type) {
      case 'retrieveAll':
        selectObj = selectConfig
        dispatch('loadStoriesData', selectObj)
        break
      case 'retrieveSubset':
        selectObj = subsetConfig
        dispatch('loadStoriesData', selectObj)
        break
      case 'filterBy':
        selectObj = {
          store: requestObj.store,
          view: 'Published',
          fields: fieldsConfig,
          filterByFormula: requestObj.query
        }
        dispatch('loadStoriesData', selectObj)
        break
    }
  },
  clearStoriesData ({ commit }) {
    commit('SET_STORIES_QUERY_DATA', [])
    commit('SET_STORIES_QUERY_DATA_LOADED', false)
  },
  async loadStory ({ commit, dispatch }, identifier) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase('Stories').find(identifier, function (err, record: any) {
      if (err) {
        commit('SET_STORIES_MESSAGE', err)
        commit('SET_STORIES_LOADED', false)
      }
      commit('SET_STORIES_LOADED', true)
      commit('SET_STORIES_DATA', record.fields)
    })
  },
  async loadRelatedStoriesData ({ commit, dispatch }, filter) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase('Stories').select({
      view: 'Published',
      fields: listConfig,
      filterByFormula: filter
    }).eachPage(function page (records, fetchNextPage: () => void) {
      const filertedRecords = records.map(function (record) {
        return record.fields
      })
      commit('SET_RELATED_DATA', filertedRecords)
      commit('SET_RELATED_LOADED', true)
      fetchNextPage()
    }, function done (err: string) {
      if (err) {
        commit('SET_RELATED_LOADED', false)
        commit('SET_RELATED_MESSAGE', err)
      }
    })
  },
  setFeaturedStoriesLoadedState ({ commit }, newState) {
    commit('SET_STORIES_FEATURED_DATA', newState)
    commit('SET_STORIES_FEATURED_LOADED', newState)
  },
  setStoriesLoadedState ({ commit }, newState) {
    commit('SET_STORIES_LOADED', newState)
    commit('SET_RELATED_LOADED', newState)
  },
  setStoriesQueriesLoadedState ({ commit }, newState) {
    commit('SET_STORIES_QUERIES_LOADED', newState)
  },
  async loadStoriesQueries ({ commit, dispatch }) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase('Stories').select(queryConfig).eachPage(function page (records, fetchNextPage: () => void) {
      records.map(function (record) {
        if (record.fields.title) {
          commit('SET_STORIES_QUERIES', record.fields.title)
        }
      })
      commit('SET_STORIES_QUERIES_LOADED', true)
      fetchNextPage()
    }, function done (err: string) {
      if (err) {
        commit('SET_STORIES_QUERIES_MESSAGE', err)
      }
    })
  }
}
