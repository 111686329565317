import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { userSignedIn, initialised } from '@/firebase'
import Home from '../views/Feed.vue'
import { watch } from 'vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:slug',
    name: 'Feed',
    component: Home,
    meta: {
      public: false,
      title: 'Feed'
    }
  },
  {
    path: '/:slug/participants',
    name: 'Participants',
    component: () => import('../views/Participants.vue'),
    meta: {
      public: false,
      title: 'Participants'
    }
  },
  {
    path: '/:slug/search=:query?&:filter?',
    name: 'Search',
    component: () => import('../views/Search.vue'),
    meta: {
      public: false,
      title: 'Search'
    }
  },
  {
    path: '/:slug/favorites/:type?',
    name: 'Favorites',
    component: () => import('../views/Favorites.vue'),
    meta: {
      public: false,
      title: 'Favorites'
    }
  },
  {
    path: '/:slug/story/:storyTitle?',
    name: 'Story',
    component: () => import('../views/Story.vue'),
    meta: {
      public: false,
      title: 'Story'
    }
  },
  {
    path: '/:slug/create-account',
    name: 'CreateAccount',
    component: () => import('../views/CreateAccount.vue'),
    meta: {
      public: true,
      title: 'Create Account'
    }
  },
  {
    path: '/:slug/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      public: true,
      title: 'Login'
    }
  },
  {
    path: '/not-found',
    name: 'Not-Found',
    component: () => import('../views/404.vue'),
    meta: {
      public: true,
      title: 'Not Found'
    }
  },
  {
    path: '/:slug/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {
      public: false,
      title: 'Privacy Policy'
    }
  },
  {
    path: '/:slug/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: {
      public: false,
      title: 'Contact'
    }
  },
  {
    path: '/:slug/terms-and-conditions',
    name: 'Terms and Conditions',
    component: () => import('../views/TermsConditions.vue'),
    meta: {
      public: false,
      title: 'Terms and Conditions'
    }
  },
  {
    path: '/:slug/help',
    name: 'Help',
    component: () => import('../views/Help.vue'),
    meta: {
      public: false,
      title: 'Help'
    }
  }
]

function wait (duration: number) {
  return new Promise((resolve) => setTimeout(resolve, duration))
}

async function tryScrollToAnchor (hash:string, timeout = 1000, delay = 100) {
  while (timeout > 0) {
    const el = document.querySelector(hash)
    if (el instanceof HTMLElement) {
      el.scrollIntoView({ behavior: 'smooth' })
      break
    }
    await wait(delay)
    timeout = timeout - delay
  }
}

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: (to) => {
    if (to.hash) {
      tryScrollToAnchor(to.hash, 1000, 100)
      return {
        el: to.hash,
        behavior: 'smooth',
        left: 0
      }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const slug = to.params.slug
  const query = to.params.query ? `: ${to.params.query}` : ''
  const title = to.meta.title
  document.title = `Insights Platform: ${slug} | ${title}${query}`

  // Check if Firebase is initialized
  if (initialised.value) {
    // if there is no logged in user, prevent navigation to protected paths
    if (!to.matched.some(record => record.meta.public) && !userSignedIn.value) {
      return next('/' + from.params.slug + '/login')
    }
    next()
  } else {
    // watch for update & follow route protection pattern above
    watch(
      () => initialised.value,
      newVal => {
        if (newVal) {
          if (!to.matched.some(record => record.meta.public) && !userSignedIn.value) {
            return next('/' + to.params.slug + '/login')
          }
          next()
        }
      }
    )
  }
})

export default router
