import { GetterTree } from 'vuex'
import { AdminState } from './types'
import { RootState } from '../types'

export const getters: GetterTree<AdminState, RootState> = {
  getBaseSlug (state): string | null {
    return state.baseSlug
  },
  getBaseName (state): string | null {
    return state.baseName
  },
  getBaseId (state): string | null {
    return state.baseId
  },
  getBaseStatus (state): number | null {
    return state.baseStatus
  },
  getFavoritesQuotes (state): Array<string> {
    return state.favoriteQuotes
  },
  getFavoritesStories (state): Array<string> {
    return state.favoriteStories
  },
  getFavoritesParticipants (state): Array<string> {
    return state.favoriteParticipants
  },
  getFavoritesImages (state): Array<string> {
    return state.favoriteImages
  },
  getFavoritesLoaded (state): boolean {
    return state.favoritesLoaded
  },
  getUserRecordId (state): string | null {
    return state.userRecordId
  },
  getUserAuthenticated (state): boolean | null {
    return state.userAuthenticated
  },
  getUserIsAdmin (state): boolean | null {
    return state.userIsAdmin
  },
  getUserTermsAccepted (state): boolean {
    return state.userTermsAccepted
  },
  getSortBy (state): string | null {
    return state.sortBy
  }
}
