const filters = {
  typePlural: function (length:number, value:string): string {
    if (length === 1) {
      if (value === 'Stories') {
        return 'Story' as string
      }
      return value.slice(0, -1) as string
    } else {
      return value
    }
  }
}
export default filters
