import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icon-account.svg'
import _imports_1 from '@/assets/icon-account-active.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1
}
const _hoisted_3 = {
  key: 0,
  class: "user-dropdown"
}
const _hoisted_4 = {
  key: 0,
  src: _imports_1
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.drawerOpen + ' account-controls')
  }, [
    _createElementVNode("div", {
      class: "user-icon",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDrawer && _ctx.toggleDrawer(...args)))
    }, [
      (!_ctx.drawerOpen)
        ? (_openBlock(), _createElementBlock("img", _hoisted_1))
        : _createCommentVNode("", true),
      (_ctx.drawerOpen)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    (_ctx.drawerOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.userSignedIn)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "submit",
                class: "btn",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.signOut && _ctx.signOut(...args)))
              }, " Sign Out "))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "user-icon",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleDrawer && _ctx.toggleDrawer(...args)))
          }, [
            (_ctx.drawerOpen)
              ? (_openBlock(), _createElementBlock("img", _hoisted_4))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}