import { MutationTree } from 'vuex'
import { PagesState, FeedDataObj, InfoDataObj } from './types'

export enum HomeMutations {
  SET_FEED_DATA = 'SET_FEED_DATA',
  SET_FEED_LOADED = 'SET_FEED_LOADED',
  SET_FEED_MESSAGE = 'SET_FEED_MESSAGE',
  SET_INFO_DATA = 'SET_INFO_DATA',
  SET_INFO_LOADED = 'SET_INFO_LOADED',
  SET_INFO_MESSAGE = 'SET_INFO_MESSAGE',
}

export const mutations: MutationTree<PagesState> = {
  [HomeMutations.SET_FEED_DATA] (state, payload: Array<FeedDataObj>) {
    state.feedData = payload
  },
  [HomeMutations.SET_FEED_LOADED] (state, payload: boolean) {
    state.feedLoaded = payload
  },
  [HomeMutations.SET_FEED_MESSAGE] (state, payload: string) {
    state.feedMessage = payload
  },
  [HomeMutations.SET_INFO_DATA] (state, payload: Array<InfoDataObj>) {
    state.infoData = payload
  },
  [HomeMutations.SET_INFO_LOADED] (state, payload: boolean) {
    state.infoLoaded = payload
  },
  [HomeMutations.SET_INFO_MESSAGE] (state, payload: string) {
    state.infoMessage = payload
  }
}
