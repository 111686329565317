import { MutationTree } from 'vuex'
import { StoryState, StoryObj } from './types'

export enum StoriesMutations {
  SET_STORIES_FEATURED_DATA = 'SET_STORIES_FEATURED_DATA',
  SET_STORIES_FEATURED_LOADED = 'SET_STORIES_FEATURED_LOADED',
  SET_RELATED_DATA = 'SET_RELATED_DATA',
  SET_RELATED_LOADED = 'SET_RELATED_LOADED',
  SET_RELATED_MESSAGE = 'SET_RELATED_MESSAGE',
  SET_STORIES_QUERIES = 'SET_STORIES_QUERIES',
  SET_STORIES_QUERIES_LOADED = 'SET_STORIES_QUERIES_LOADED',
  SET_STORIES_QUERIES_MESSAGE = 'SET_STORIES_QUERIES_MESSAGE',
  SET_STORIES_QUERY_DATA = 'SET_STORIES_QUERY_DATA',
  SET_STORIES_QUERY_DATA_LOADED = 'SET_STORIES_QUERY_DATA_LOADED'
}

export const mutations: MutationTree<StoryState> = {
  [StoriesMutations.SET_RELATED_DATA] (state, payload: Array<StoryObj>) {
    state.relatedData = payload
  },
  [StoriesMutations.SET_RELATED_LOADED] (state, payload: boolean) {
    state.relatedLoaded = payload
  },
  [StoriesMutations.SET_RELATED_MESSAGE] (state, payload: string) {
    state.relatedMessage = payload
  },
  [StoriesMutations.SET_STORIES_QUERIES] (state, payload: string) {
    state.queries.push(payload)
  },
  [StoriesMutations.SET_STORIES_QUERIES_LOADED] (state, payload: boolean) {
    state.queriesLoaded = payload
  },
  [StoriesMutations.SET_STORIES_QUERIES_MESSAGE] (state, payload: string) {
    state.queriesMessage = payload
  },
  [StoriesMutations.SET_STORIES_QUERY_DATA] (state, payload: Array<StoryObj>) {
    state.queryData = payload
  },
  [StoriesMutations.SET_STORIES_QUERY_DATA_LOADED] (state, payload: boolean) {
    state.queryDataLoaded = payload
  },
  [StoriesMutations.SET_STORIES_FEATURED_DATA] (state, payload: Array<StoryObj>) {
    state.featuredData = payload
  },
  [StoriesMutations.SET_STORIES_FEATURED_LOADED] (state, payload: boolean) {
    state.featuredLoaded = payload
  }
}
