import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { AdminState } from './types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

const state: AdminState = {
  baseName: null,
  baseId: null,
  baseSlug: null,
  baseStatus: null,
  baseMessage: null,
  favoriteQuotes: [],
  favoriteStories: [],
  favoriteImages: [],
  favoriteParticipants: [],
  favoritesLoaded: false,
  userLoggedIn: false,
  userRecordId: null,
  userName: null,
  userEmail: null,
  userAuthenticated: null,
  userIsAdmin: null,
  userTermsAccepted: false,
  sortBy: null
}

export const projectBase: Module<AdminState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
