import { MutationTree } from 'vuex'
import { ImageState, ImageObj } from './types'

export enum ImagesMutations {
  SET_IMAGES_DATA = 'SET_IMAGES_DATA',
  SET_IMAGES_QUERIES = 'SET_IMAGES_QUERIES',
  SET_IMAGES_LOADED = 'SET_IMAGES_LOADED',
  SET_IMAGES_MESSAGE = 'SET_IMAGES_MESSAGE',
  SET_IMAGES_QUERIES_LOADED = 'SET_IMAGES_QUERIES_LOADED',
  SET_IMAGES_QUERIES_MESSAGE = 'SET_IMAGES_QUERIES_MESSAGE'
}

export const mutations: MutationTree<ImageState> = {
  [ImagesMutations.SET_IMAGES_DATA] (state, payload: Array<ImageObj>) {
    state.data = payload
  },
  [ImagesMutations.SET_IMAGES_LOADED] (state, payload: boolean) {
    state.loaded = payload
  },
  [ImagesMutations.SET_IMAGES_MESSAGE] (state, payload: string) {
    state.message = payload
  },
  [ImagesMutations.SET_IMAGES_QUERIES] (state, payload: string) {
    state.queries.push(payload)
  },
  [ImagesMutations.SET_IMAGES_QUERIES_LOADED] (state, payload: boolean) {
    state.queriesLoaded = payload
  },
  [ImagesMutations.SET_IMAGES_QUERIES_MESSAGE] (state, payload: string) {
    state.queriesMessage = payload
  }
}
