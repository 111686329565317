import * as firebase from 'firebase/app'
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth'
import { getFirestore, collection, doc, getDocs, addDoc, deleteDoc } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { ref } from 'vue'
// import store from './store'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

firebase.initializeApp(config)
const db = getFirestore()
const provider = new GoogleAuthProvider()
provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
const analytics = getAnalytics()

export const initialised = ref(false)
export const userSignedIn = ref(false)
export const userData = ref([] as any)
export const users = ref([] as any)
export const errMessage = ref()
export const emailSent = ref(false)

const auth = getAuth()

// CREATE FIREBASE AUTHENTICATON USER
export const createUserAccount = async (user:any): Promise<any> => {
  const email = user.email
  const password = user.password
  const customClaims = {
    name: user.name,
    project: user.project,
    email: user.email
  }
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user
      // console.log('FIREBASE >>>> USERCREDENTIALS', user)
    })
    .catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      console.log(errorCode)
    })
  // console.log('FIREBASE >>>> SETTING CUSTOMCLAIMS', customClaims)
  createUserDoc(customClaims)
}

// ADD USER DATA TO FIRESTORE "USERS" DATABASE
export const createUserDoc = async (user:any): Promise<any> => {
  // console.log('FIREBASE >>>> writing to userdocs')
  try {
    const docRef = await addDoc(collection(db, 'users'), user)
    // console.log('FIREBASE >>>> Document written with ID: ', docRef.id)
    // useLoadUsers()
  } catch (e) {
    // console.error('FIREBASE >>>> Error adding document: ', e)
  }
}

// RESTART LOGIN
export const restartLogin = (): void => {
  errMessage.value = null
}

// AUTHENTICATE USER ACCOUNT
export const authenticateAccount = async (user:any, slug:string): Promise<any> => {
  errMessage.value = null
  const email = user.email
  const password = user.password
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user
      // matchUser(slug)
    })
    .catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      errMessage.value = errorCode
      console.log(errorCode)
    })
}

// SEND PASSWORD RESET EMAIL
export const sendUserPasswordResetEmail = async (email:string): Promise<any> => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      emailSent.value = true
      errMessage.value = `Passord Reset Email Sent to ${email}`
    })
    .catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      errMessage.value = errorCode
      emailSent.value = false
      console.log(errorCode)
    })
}

// SIGNOUT USER ACCOUNT
export const userSignOut = (): void => {
  signOut(auth).then(() => {
    // console.log('logging out')
    userData.value = []
    userSignedIn.value = false
  }).catch((error) => {
    errMessage.value = error.errorMessage
    const errorCode = error.code
    console.log(errorCode)
  })
}

// WATCH AUTHENTICATION STATE
onAuthStateChanged(auth, (user) => {
  initialised.value = true
  // console.log('firebase initialised')
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid
    userData.value = {
      uid: uid,
      email: user.email
    }
    userSignedIn.value = true
    // console.log('logged in', userData.value)
  } else {
    userData.value = []
    userSignedIn.value = false
    console.log('logged out')
  }
})

// UNUSED– CHECK TO SEE THAT USER IS LOGGING INTO CORRECT PROJECT
// TODO: ADD ELSE CASE
export const matchUser = async (slug:string): Promise<any> => {
  const querySnapshot = await getDocs(collection(db, 'users'))
  querySnapshot.docs.map((doc) => {
    const userEmail = userData.value.email.toString()
    const docEmail = doc.data().email.toString()
    const docProject = doc.data().project.toString()
    if (userEmail === docEmail && docProject === slug) {
      // ALLOW SITE NAVIGATION IF USER PROJECT MATCHES CURRENT ROUTE
      userSignedIn.value = true
    }
  })
}

export default firebase
