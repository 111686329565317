import { MutationTree } from 'vuex'
import { AdminState } from './types'

export enum AdminMutations {
  SET_BASE_SLUG = 'SET_BASE_SLUG',
  SET_BASE_NAME = 'SET_BASE_NAME',
  SET_BASE_ID = 'SET_BASE_ID',
  SET_BASE_STATUS = 'SET_BASE_STATUS',
  SET_BASE_MESSAGE = 'SET_BASE_MESSAGE',
  SET_FAVORITES_LOADED = 'SET_FAVORITES_LOADED',
  SET_FAVORITE_QUOTES = 'SET_FAVORITE_QUOTES',
  ADD_FAVORITE_QUOTE = 'ADD_FAVORITE_QUOTE',
  ADD_FAVORITE_STORY = 'ADD_FAVORITE_STORY',
  SET_FAVORITE_STORIES = 'SET_FAVORITE_STORIES',
  SET_FAVORITE_IMAGES = 'SET_FAVORITE_IMAGES',
  ADD_FAVORITE_IMAGE = 'ADD_FAVORITE_IMAGE',
  SET_FAVORITE_PARTICIPANTS = 'SET_FAVORITE_PARTICIPANTS',
  ADD_FAVORITE_PARTICIPANT = 'ADD_FAVORITE_PARTICIPANT',
  SET_USER_NAME = 'SET_USER_NAME',
  SET_USER_RECORD_ID = 'SET_USER_RECORD_ID',
  SET_USER_EMAIL = 'SET_USER_EMAIL',
  SET_USER_LOGGED_IN = 'SET_USER_LOGGED_IN',
  SET_USER_AUTHENTICATED = 'SET_USER_AUTHENTICATED',
  SET_USER_IS_ADMIN = 'SET_USER_IS_ADMIN',
  SET_USER_TERMS_ACCEPTED = 'SET_USER_TERMS_ACCEPTED',
  SET_SORT_BY = 'SET_SORT_BY'
}

export const mutations: MutationTree<AdminState> = {
  [AdminMutations.SET_BASE_SLUG] (state, payload: string) {
    state.baseSlug = payload
  },
  [AdminMutations.SET_BASE_NAME] (state, payload: string) {
    state.baseName = payload
  },
  [AdminMutations.SET_BASE_ID] (state, payload: string) {
    state.baseId = payload
  },
  [AdminMutations.SET_USER_NAME] (state, payload: string) {
    state.userName = payload
  },
  [AdminMutations.SET_BASE_STATUS] (state, payload: number) {
    state.baseStatus = payload
  },
  [AdminMutations.SET_BASE_MESSAGE] (state, payload: string) {
    state.baseMessage = payload
  },
  [AdminMutations.SET_FAVORITES_LOADED] (state, payload: boolean) {
    state.favoritesLoaded = payload
  },
  [AdminMutations.SET_FAVORITE_QUOTES] (state, payload: Array<string>) {
    state.favoriteQuotes = payload
  },
  [AdminMutations.ADD_FAVORITE_QUOTE] (state, payload: Array<string>) {
    state.favoriteQuotes = payload
  },
  [AdminMutations.SET_FAVORITE_STORIES] (state, payload: Array<string>) {
    state.favoriteStories = payload
  },
  [AdminMutations.ADD_FAVORITE_STORY] (state, payload: Array<string>) {
    state.favoriteStories = payload
  },
  [AdminMutations.SET_FAVORITE_PARTICIPANTS] (state, payload: Array<string>) {
    state.favoriteParticipants = payload
  },
  [AdminMutations.ADD_FAVORITE_PARTICIPANT] (state, payload: Array<string>) {
    state.favoriteParticipants = payload
  },
  [AdminMutations.SET_FAVORITE_IMAGES] (state, payload: Array<string>) {
    state.favoriteImages = payload
  },
  [AdminMutations.ADD_FAVORITE_IMAGE] (state, payload: Array<string>) {
    state.favoriteImages = payload
  },
  [AdminMutations.SET_USER_LOGGED_IN] (state, payload: boolean) {
    state.userLoggedIn = payload
  },
  [AdminMutations.SET_USER_RECORD_ID] (state, payload: string) {
    state.userRecordId = payload
  },
  [AdminMutations.SET_USER_EMAIL] (state, payload: string) {
    state.userEmail = payload
  },
  [AdminMutations.SET_USER_AUTHENTICATED] (state, payload: boolean) {
    state.userAuthenticated = payload
  },
  [AdminMutations.SET_USER_IS_ADMIN] (state, payload: boolean) {
    state.userIsAdmin = payload
  },
  [AdminMutations.SET_USER_TERMS_ACCEPTED] (state, payload: boolean) {
    state.userTermsAccepted = payload
  },
  [AdminMutations.SET_SORT_BY] (state, payload: null | string) {
    state.sortBy = payload
  }
}
