
import { defineComponent, watchEffect, computed, ref } from 'vue'
import { extendRefernceItem, setItemClass, targetRoute } from '@/composables/referenceFunctions'
import ArrayJoin from '@/components/ArrayJoin.vue'
import { StoryObj } from '@/store/stories/types'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'StoryCard',
  props: {
    storyProp: Object
  },
  components: {
    ArrayJoin
  },
  setup (props): any {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const itemClass = ref('clipped')
    const isFavorite = ref(false)
    const item = computed(() => props.storyProp as unknown as StoryObj)
    const favorites = computed(() => store.getters.getFavoritesStories as Array<string>)
    const buttonClass = computed(() => isFavorite.value ? 'selected' : '')

    watchEffect(() => {
      itemClass.value = setItemClass(props.storyProp?.id, route)
      if (favorites.value) {
        isFavorite.value = favorites.value.indexOf(props.storyProp?.id) >= 0
      }
    })
    const onClick = (id:string) => {
      extendRefernceItem(id, route)
      router.push(targetRoute.value)
    }
    const favoriteClick = (id:string) => {
      if (!isFavorite.value) {
        store.dispatch('setFavorite', {
          type: 'story',
          id: id,
          list: [id, ...favorites.value]
        })
      } else {
        const newArr = favorites.value.filter((item:string) => item !== id)
        store.dispatch('setFavorite', {
          type: 'story',
          id: id,
          list: newArr
        })
      }
    }
    return {
      item,
      itemClass,
      favorites,
      isFavorite,
      buttonClass,
      onClick,
      favoriteClick
    }
  }
})
