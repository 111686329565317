
import { defineComponent } from 'vue'
import InfoModal from '@/components/InfoModal.vue'
import MasonryWall from '@yeger/vue-masonry-wall'
import { mapGetters } from 'vuex'
import StoryCard from '@/components/StoryCard.vue'
import QuoteCard from '@/components/QuoteCard.vue'
import ImageCard from '@/components/ImageCard.vue'
import { FeedDataObj } from '@/store/pages/types'

export default defineComponent({
  name: 'Feed',
  components: {
    InfoModal,
    MasonryWall,
    StoryCard,
    QuoteCard,
    ImageCard
  },
  data () {
    return {
      mergedList: [],
      count: 12,
      increment: 12
    }
  },
  computed: {
    ...mapGetters({
      feedContent: 'getFeedData',
      feedLoaded: 'getFeedLoaded',
      feedMessage: 'getFeedMessage'
    }),
    currentDate () {
      const current = new Date()
      const month = current.toLocaleString('default', { month: 'long' })
      const date = `${month} ${current.getDate()}, ${current.getFullYear()}`
      return date
    }
  },
  mounted () {
    this.loadFeedData()
  },
  watch: {
    feedContent () {
      this.mergedList = this.feedContent.slice(0, this.increment)
    }
  },
  methods: {
    loadFeedData () {
      this.$store.dispatch('loadFeedData')
    },
    storyItem (item:FeedDataObj) {
      return {
        id: item.storyId ? item.storyId[0] : '',
        title: item.storyTitle ? item.storyTitle[0] : '',
        ledeText: item.storyLede ? item.storyLede[0] : '',
        linkedParticipantsNames: item.storyParticipants,
        programsName: item.storyProgramsName,
        linkedTagsNames: item.storyTags
      }
    },
    imageItem (item:FeedDataObj) {
      return {
        imageId: item.imageId ? item.imageId[0] : '',
        description: item.imageTitle ? item.imageTitle[0] : '',
        attachments: item.imageAttachments,
        linkedParticipantsNames: item.imageParticipants,
        programsName: item.imageProgramsName,
        linkedStoriesTitles: item.imageStories,
        linkedTagsNames: item.imageTags
      }
    },
    quoteItem (item:FeedDataObj) {
      return {
        quoteId: item.quoteId ? item.quoteId[0] : '',
        body: item.quoteText ? item.quoteText[0] : '',
        linkedParticipantsNames: item.quoteParticipants,
        programsName: item.quoteProgramsName,
        linkedTagsNames: item.quoteTags
      }
    },
    append: function () {
      this.count = this.count + this.increment
      this.mergedList = this.feedContent.slice(0, this.count)
    }
  }
})
