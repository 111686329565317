
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import vClickOutside from 'click-outside-vue3'

export default defineComponent({
  name: 'SearchForm',
  data () {
    return {
      filter: this.$route.params.filter,
      newQuery: this.$route.params.setQuery,
      query: this.$route.params.query,
      resultItems: [],
      searchActive: false
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    revealState () {
      if (this.searchActive && this.resultItems.length > 0) {
        return true
      } else {
        return false
      }
    },
    ...mapGetters({
      tagsQueries: 'getTagsQueries',
      tagsQueriesLoaded: 'getTagsQueriesLoaded',
      tagsQueriesMessage: 'getTagsQueriesMessage',
      quotesQueries: 'getQuotesQueries',
      quotesQueriesLoaded: 'getQuotesQueriesLoaded',
      quotesQueriesMessage: 'getQuotesQueriesMessage',
      participantsQueries: 'getParticipantsQueries',
      participantsQueriesLoaded: 'getParticipantsQueriesLoaded',
      participantsQueriesMessage: 'getParticipantsQueriesMessage',
      imagesQueries: 'getImagesQueries',
      imagesQueriesLoaded: 'getImagesQueriesLoaded',
      imagesQueriesMessage: 'getImagesQueriesMessage',
      storiesQueries: 'getStoriesQueries',
      storiesQueriesLoaded: 'getStoriesQueriesLoaded',
      storiesQueriesMessage: 'getStoriesQueriesMessage'
    })
  },
  mounted () {
    if (!this.tagsQueriesLoaded) {
      this.$store.dispatch('loadTagsQueries')
    } if (!this.participantsQueriesLoaded) {
      this.$store.dispatch('loadParticipantsQueries')
    } if (!this.imagesQueriesLoaded) {
      this.$store.dispatch('loadImagesQueries')
    } if (!this.quotesQueriesLoaded) {
      this.$store.dispatch('loadQuotesQueries')
    } if (!this.storiesQueriesLoaded) {
      this.$store.dispatch('loadStoriesQueries')
    }
    this.newQuery = this.$route.params.query
  },
  watch: {
    $route (to, from) {
      if (this.$route.params.query) {
        this.searchActive = false
        if (to.params.query !== from.params.query) {
          this.newQuery = this.$route.params.query
        }
      }
    }
  },
  methods: {
    onClickOutside () {
      this.searchActive = false
    },
    search () {
      if (this.newQuery !== '') {
        const queryString = this.newQuery.toString().toLowerCase()
        let joinedArr = this.tagsQueries.concat(this.imagesQueries, this.participantsQueries, this.storiesQueries)
        if (this.$route.params.filter) {
          if (this.$route.params.filter === 'images') {
            joinedArr = this.imagesQueries
          } if (this.$route.params.filter === 'participants') {
            joinedArr = this.participantsQueries
          } if (this.$route.params.filter === 'stories') {
            joinedArr = this.storiesQueries
          }
        }
        const updatedArr = joinedArr.filter((item:string) => item.toLowerCase().search(queryString) >= 0)
        const uniqueArr = updatedArr.filter((item:string, index:number, self:Array<string>) => self.indexOf(item) === index)
        this.resultItems = uniqueArr
      } else {
        this.resultItems = this.tagsQueries.slice(0, 6)
      }
    },
    onSelect (selectedQuery:string) {
      this.$router.push({
        name: 'Search',
        params: {
          query: selectedQuery,
          filter: this.$route.params.filter
        }
      })
      window.scrollTo(0, 0)
      this.searchActive = false
    },
    setQuery () {
      this.$router.push({
        name: 'Search',
        params: {
          query: this.newQuery,
          filter: this.$route.params.filter
        }
      })
    },
    toggleFocus () {
      this.searchActive = !this.searchActive
      if (this.newQuery === '' || this.newQuery === undefined) {
        this.resultItems = this.tagsQueries
      }
    }
  }
})
