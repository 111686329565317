import { MutationTree } from 'vuex'
import { ParticipantsState, ParticipantObj } from './types'

export enum ParticipantMutations {
  SET_PARTICIPANTS_DATA = 'SET_PARTICIPANTS_DATA',
  SET_PARTICIPANTS_LOADED = 'SET_PARTICIPANTS_LOADED',
  SET_PARTICIPANTS_MESSAGE = 'SET_PARTICIPANTS_MESSAGE',
  SET_PARTICIPANTS_QUERIES = 'SET_PARTICIPANTS_QUERIES',
  SET_PARTICIPANTS_QUERIES_LOADED = 'SET_PARTICIPANTS_QUERIES_LOADED',
  SET_PARTICIPANTS_QUERIES_MESSAGE = 'SET_PARTICIPANTS_QUERIES_MESSAGE'
}

export const mutations: MutationTree<ParticipantsState> = {
  [ParticipantMutations.SET_PARTICIPANTS_DATA] (state, payload: Array<ParticipantObj>) {
    state.data = payload
  },
  [ParticipantMutations.SET_PARTICIPANTS_LOADED] (state, payload: boolean) {
    state.loaded = payload
  },
  [ParticipantMutations.SET_PARTICIPANTS_MESSAGE] (state, payload: string) {
    state.message = payload
  },
  [ParticipantMutations.SET_PARTICIPANTS_QUERIES] (state, payload: string) {
    state.queries.push(payload)
  },
  [ParticipantMutations.SET_PARTICIPANTS_QUERIES_LOADED] (state, payload: boolean) {
    state.queriesLoaded = payload
  },
  [ParticipantMutations.SET_PARTICIPANTS_QUERIES_MESSAGE] (state, payload: string) {
    state.queriesMessage = payload
  }
}
