import { GetterTree } from 'vuex'
import { PagesState, FeedDataObj, InfoDataObj } from './types'
import { RootState } from '../types'

export const getters: GetterTree<PagesState, RootState> = {
  getFeedData (state): Array<FeedDataObj> {
    return state.feedData
  },
  getFeedLoaded (state): boolean {
    return state.feedLoaded
  },
  getFeedMessage (state): string | null {
    return state.feedMessage
  },
  getInfoData (state): Array<InfoDataObj> {
    return state.infoData
  },
  getInfoLoaded (state): boolean {
    return state.infoLoaded
  },
  getInfoMessage (state): string | null {
    return state.infoMessage
  }
}
