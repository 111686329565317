import { MutationTree } from 'vuex'
import { QuoteState, QuoteObj } from './types'

export enum QuotesMutations {
  SET_QUOTES_DATA = 'SET_QUOTES_DATA',
  SET_QUOTES_QUERIES = 'SET_QUOTES_QUERIES',
  SET_QUOTES_LOADED = 'SET_QUOTES_LOADED',
  SET_QUOTES_MESSAGE = 'SET_QUOTES_MESSAGE',
  SET_QUOTES_QUERIES_LOADED = 'SET_QUOTES_QUERIES_LOADED',
  SET_QUOTES_QUERIES_MESSAGE = 'SET_QUOTES_QUERIES_MESSAGE',
  SET_QUOTES_QUERY_DATA = 'SET_QUOTES_QUERY_DATA',
  SET_QUOTES_QUERY_DATA_LOADED = 'SET_QUOTES_QUERY_DATA_LOADED'
}

export const mutations: MutationTree<QuoteState> = {
  [QuotesMutations.SET_QUOTES_QUERIES] (state, payload: string) {
    state.queries.push(payload)
  },
  [QuotesMutations.SET_QUOTES_QUERIES_LOADED] (state, payload: boolean) {
    state.queriesLoaded = payload
  },
  [QuotesMutations.SET_QUOTES_QUERIES_MESSAGE] (state, payload: string) {
    state.queriesMessage = payload
  },
  [QuotesMutations.SET_QUOTES_QUERY_DATA] (state, payload: Array<QuoteObj>) {
    state.queryData = payload
  },
  [QuotesMutations.SET_QUOTES_QUERY_DATA_LOADED] (state, payload: boolean) {
    state.queryDataLoaded = payload
  }
}
