import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { StoryState } from './types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

const state: StoryState = {
  featuredData: [],
  featuredLoaded: false,
  queryData: [],
  queryDataLoaded: false,
  relatedData: [],
  relatedLoaded: false,
  relatedMessage: null,
  queries: [],
  queriesLoaded: false,
  queriesMessage: null
}

export const stories: Module<StoryState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
