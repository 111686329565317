
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Footer',
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    slug () {
      return this.$route.params.slug
    }
  },
  methods: {
    toggleOpen () {
      this.isOpen = !this.isOpen
    }
  }
})
