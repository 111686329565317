import { ActionTree } from 'vuex'
import { RootState } from '../types'
import { ParticipantsState } from './types'
import Airtable from 'airtable'

const base = new Airtable({
  apiKey: process.env.VUE_APP_AIRTABLE_API_KEY
})

const view = 'Participants'

const fieldsConfig = [
  'type',
  'id',
  'name',
  'location',
  'linkedImages',
  'linkedImagesAttachments',
  'linkedQuotes',
  'linkedQuotesText',
  'linkedStories',
  'linkedStoriesTitles',
  'linkedTags',
  'linkedTagsNames',
  'participantIdentifierTitles',
  'participantIdentifierKeys',
  'programsName',
  'createdDate'
]

const queryConfig = {
  view: view,
  fields: ['name', 'location']
}

const selectConfig = {
  view: view,
  fields: fieldsConfig
}

export const actions: ActionTree<ParticipantsState, RootState> = {
  async loadParticipantsData ({ commit, dispatch }, selectObj) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase(view).select(selectObj).eachPage(function page (records, fetchNextPage: () => void) {
      const filertedRecords = records.map(function (record) {
        return record.fields
      })
      commit('SET_PARTICIPANTS_DATA', filertedRecords)
      commit('SET_PARTICIPANTS_LOADED', true)
      fetchNextPage()
    }, function done (err: string) {
      if (err) {
        commit('SET_PARTICIPANTS_LOADED', false)
        commit('SET_PARTICIPANTS_MESSAGE', err)
      }
    })
  },
  getParticipantsData ({ dispatch }, requestObj) {
    let selectObj: Record<string, unknown>
    switch (requestObj.type) {
      case 'retrieveAll':
        selectObj = selectConfig
        dispatch('loadParticipantsData', selectObj)
        break
      case 'filterBy':
        selectObj = {
          view: view,
          fields: fieldsConfig,
          filterByFormula: requestObj.query
        }
        dispatch('loadParticipantsData', selectObj)
        break
    }
  },
  async loadParticipantsQueries ({ commit, dispatch }) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase(view).select(queryConfig).eachPage(function page (records, fetchNextPage: () => void) {
      records.map(function (record) {
        if (record.fields.name) {
          commit('SET_PARTICIPANTS_QUERIES', record.fields.name)
        } if (record.fields.location) {
          commit('SET_PARTICIPANTS_QUERIES', record.fields.location)
        }
      })
      commit('SET_PARTICIPANTS_QUERIES_LOADED', true)
      fetchNextPage()
    }, function done (err: string) {
      if (err) {
        commit('SET_PARTICIPANTS_QUERIES_MESSAGE', err)
      }
    })
  },
  setParticipantsLoadedState ({ commit }, payload) {
    commit('SET_PARTICIPANTS_LOADED', payload)
  }
}
