import { ref, reactive } from 'vue'

export const targetRoute = reactive({} as any)
export const isExtended = ref(false)

export const setItemClass = (itemId:string, route:any) => {
  const idSelector = route.hash.substring(1)
  if (itemId === idSelector) {
    return 'extended'
  } else {
    return 'clipped'
  }
}

export const extendRefernceItem = (itemName:string, route:any):any => {
  const routeName = route.name?.toString()
  const targetName = '#' + itemName
  const routeQuery = route.params.query
  const routeFilter = route.params.filter

  if (setItemClass(itemName, route) === 'extended') {
    targetRoute.value = {
      name: routeName,
      params: {
        query: routeQuery,
        filter: routeFilter
      }
    }
  } else {
    targetRoute.value = {
      name: routeName,
      hash: targetName,
      params: {
        query: routeQuery,
        filter: routeFilter
      }
    }
  }
}

export const setFavorite = (isFavorite:boolean, itemId:string) => {
  // if (!isFavorite) {
  //   this.$store.dispatch('setFavorite', { type: 'quote', id: itemId, list: [itemId, ...this.favoritesArr] })
  // } else {
  //   const newArr = this.favoritesArr.filter(item => item !== itemId)
  //   this.$store.dispatch('setFavorite', { type: 'quote', id: itemId, list: newArr })
  // }
}
