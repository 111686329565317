import { ActionTree } from 'vuex'
import { RootState } from '../types'
import { TagsState } from './types'
import Airtable from 'airtable'
const base = new Airtable({
  apiKey: process.env.VUE_APP_AIRTABLE_API_KEY
})
const view = 'Tags'

const fieldsConfig = [
  'name',
  'linkedParticipants',
  'linkedParticipantsNames',
  'linkedStories',
  'linkedStoriesTitles',
  'linkedQuotes',
  'linkedQuotesText',
  'linkedMedia'
]

const selectConfig = {
  view: view,
  fields: fieldsConfig
}

const queryConfig = {
  view: view,
  fields: ['name']
}
export const actions: ActionTree<TagsState, RootState> = {
  async loadTagsData ({ commit, dispatch }, selectObj) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase(view).select(selectObj).eachPage(function page (records, fetchNextPage: () => void) {
      const filertedRecords = records.map(function (record) {
        return record.fields
      })
      commit('SET_TAGS_DATA', filertedRecords)
      commit('SET_TAGS_LOADED', true)
      fetchNextPage()
    }, function done (err: string) {
      if (err) {
        commit('SET_TAGS_LOADED', false)
        commit('SET_TAGS_MESSAGE', err)
      }
    })
  },
  getTagsData ({ dispatch }, requestObj) {
    let selectObj: Record<string, unknown>
    switch (requestObj.type) {
      case 'retrieveAll':
        selectObj = selectConfig
        dispatch('loadTagsData', selectObj)
        break
      case 'filterBy':
        selectObj = {
          view: view,
          fields: fieldsConfig,
          filterByFormula: requestObj.query
        }
        dispatch('loadTagsData', selectObj)
        break
    }
  },
  async loadTagsQueries ({ commit, dispatch }) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase(view).select(queryConfig).eachPage(function page (records, fetchNextPage: () => void) {
      records.map(function (record) {
        if (record.fields.name) {
          commit('SET_TAGS_QUERIES', record.fields.name)
        }
        commit('SET_TAGS_QUERIES_LOADED', true)
      })
      fetchNextPage()
    }, function done (err: string) {
      if (err) {
        commit('SET_TAGS_QUERIES_MESSAGE', err)
      }
    })
  }
}
