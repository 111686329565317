import Vuex, { StoreOptions } from 'vuex'
import { RootState } from '@/store/types'
import { participants } from '@/store/participants'
import { images } from '@/store/images'
import { quotes } from '@/store/quotes'
import { stories } from '@/store/stories'
import { tags } from '@/store/tags'
import { pages } from '@/store/pages'
import { projectBase } from '@/store/projectBase'

const store: StoreOptions<RootState> = {
  modules: {
    projectBase,
    participants,
    images,
    quotes,
    stories,
    tags,
    pages
  }
}

export default new Vuex.Store<RootState>(store)
