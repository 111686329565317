import { GetterTree } from 'vuex'
import { ParticipantsState, ParticipantObj } from './types'
import { RootState } from '../types'

export const getters: GetterTree<ParticipantsState, RootState> = {
  getParticipantsData (state): Array<ParticipantObj> {
    return state.data
  },
  getParticipantsLoaded (state): boolean {
    return state.loaded
  },
  getParticipantsMessage (state): string | null {
    return state.message
  },
  getParticipantsQueries (state): Array<string> {
    return state.queries
  },
  getParticipantsQueriesLoaded (state): boolean {
    return state.queriesLoaded
  },
  getParticipantsQueriesMessage (state): string | null {
    return state.queriesMessage
  }
}
