import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nameArray, (item, index) => {
    return (_openBlock(), _createElementBlock("li", {
      class: _normalizeClass(_ctx.joinedProp + ' reference-item'),
      key: index,
      onClick: ($event: any) => (_ctx.onClick(index as number))
    }, [
      (_ctx.joinedProp === 'Tags')
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, " #"))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString(_ctx.nameArray[index]), 1)
    ], 10, _hoisted_1))
  }), 128))
}