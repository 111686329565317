import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { ParticipantsState } from './types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

const state: ParticipantsState = {
  data: [],
  loaded: false,
  message: null,
  queries: [],
  queriesLoaded: false,
  queriesMessage: null,
  queryResults: []
}

export const participants: Module<ParticipantsState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
