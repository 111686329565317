import { ActionTree } from 'vuex'
import { AdminState } from './types'
import { RootState } from '../types'
import Airtable from 'airtable'

const projectBase = new Airtable({
  apiKey: process.env.VUE_APP_AIRTABLE_API_KEY
}).base(process.env.VUE_APP_AIRTABLE_ADMIN_BASE)

const base = new Airtable({
  apiKey: process.env.VUE_APP_AIRTABLE_API_KEY
})

export const actions: ActionTree<AdminState, RootState> = {
  // CHECK TO SEE IF BASE_ID HAS ALREADY BEEN SET
  // IF NOT, CONNECT TO ADMIN BASE TO CONNECT TO THE PROJECT BASE
  async checkBase ({ dispatch, getters }) {
    let baseId
    if (!getters.getBaseId) {
      baseId = await dispatch('connectToBase', getters.getBaseSlug)
    } else {
      baseId = getters.getBaseId
    }
    return baseId
  },
  async connectToBase ({ commit }, routeParam) {
    // console.log('connecting to base')
    const selectConfig = {
      view: 'Projects',
      fields: ['name', 'slug', 'baseID'],
      filterByFormula: `slug = "${routeParam}"`
    }
    try {
      const newBaseID = await projectBase(selectConfig.view).select(selectConfig).all()
      commit('SET_BASE_STATUS', null)
      commit('SET_BASE_NAME', newBaseID[0].fields.name)
      commit('SET_BASE_ID', newBaseID[0].fields.baseID)
      return newBaseID[0].fields.baseID
    } catch (e) {
      // IF THERE ARE NO CORRESPONDING BASES FOR THE CURRENT SLUG, RE ROUTE TO LOGIN PAGE
      // console.log('cant connect to base')
      commit('SET_BASE_STATUS', 404)
    }
  },
  // CHECK ADMINBASE/TABLE FOR INSTANCES OF LOGIN EMAIL
  checkAdminAccounts ({ commit }, userEmail) {
    projectBase('Admins').select({
      filterByFormula: `loginEmail = '${userEmail}'`
    }).eachPage(function page (records, fetchNextPage) {
      if (records.length > 0) {
        commit('SET_USER_AUTHENTICATED', true)
        commit('SET_USER_IS_ADMIN', true)
      }
      if (records.length === 0) {
        commit('SET_USER_AUTHENTICATED', false)
      }
      fetchNextPage()
    }, function done (err) {
      if (err) { console.error('no match', err) }
    })
  },
  // SET PROJECTS SLUG
  setBaseSlug ({ commit }, slug) {
    commit('SET_BASE_SLUG', slug)
  },
  // SET 404 ON ERROR
  setBaseStatus ({ commit }, payload) {
    commit('SET_BASE_STATUS', payload)
  },
  // SET USERLOGGED IN STATE
  setUserLoggedIn ({ commit }, payload) {
    commit('SET_USER_LOGGED_IN', true)
    commit('SET_USER_EMAIL', payload.email)
  },
  // FIND SEARCH USER ACCOUNTS FOR LOGINEMAIL
  // IF NO MATCH, SEARCH FOR ADMIN REFERENCE
  // IF FOUND, GET RECORD ID AND SEARCH FOR FAVORITES
  async findUserRecordId ({ commit, dispatch }, payload) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    projectBase('User Accounts').select({
      view: 'Grid view',
      filterByFormula: `loginEmail = '${payload}'`
    }).eachPage(function page (records, fetchNextPage) {
      records.forEach(function (record) {
        const recordId = record.get('recordId')
        commit('SET_USER_RECORD_ID', recordId)
        commit('SET_USER_AUTHENTICATED', true)
        dispatch('retrieveFavorites', 'quotes')
        dispatch('retrieveFavorites', 'stories')
        dispatch('retrieveFavorites', 'images')
      })
      if (records.length === 0) {
        dispatch('checkAdminAccounts', payload)
      }
      fetchNextPage()
    }, function done (err) {
      if (err) { console.error('no match', err) }
    })
  },
  // SET USER AUTHENTICATED TO NULL ON LOGOUT
  resetUserAuthenticated ({ commit }) {
    commit('SET_USER_AUTHENTICATED', null)
  },
  // UPDATE USER FAVORITES LIST
  // ACCEPTS FAVROTIE TYPE AND TARGET MUTATION IN PAYLOAD
  async updateFavorites ({ commit, dispatch }, payload) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    projectBase('User Accounts').update(payload.reference, function (err:unknown, records:any) {
      if (err) {
        console.error('AIRTABLE ERR:', err)
        return
      }
      records.forEach(function (record:any) {
        let addedRecord = record.get(payload.record)
        if (addedRecord === undefined) {
          addedRecord = []
        }
        commit(payload.commit, addedRecord)
      })
    })
  },
  // DEFINES TYPES AND TARGET MUTATIONS FOR UPDATE FAVORITES
  setFavorite ({ dispatch, getters }, payload) {
    const recordId = getters.getUserRecordId
    const quotesObj = {
      reference: [{
        id: recordId,
        fields: { favoriteQuotes: payload.list }
      }],
      commit: 'ADD_FAVORITE_QUOTE',
      record: 'favoriteQuotes'
    }
    const storiesObj = {
      reference: [{
        id: recordId,
        fields: { favoriteStories: payload.list }
      }],
      commit: 'ADD_FAVORITE_STORY',
      record: 'favoriteStories'
    }
    const imagesObj = {
      reference: [{
        id: recordId,
        fields: { favoriteImages: payload.list }
      }],
      commit: 'ADD_FAVORITE_IMAGE',
      record: 'favoriteImages'
    }
    switch (payload.type) {
      case 'quote':
        dispatch('updateFavorites', quotesObj)
        break
      case 'story':
        dispatch('updateFavorites', storiesObj)
        break
      case 'image':
        dispatch('updateFavorites', imagesObj)
        break
    }
  },
  // GET FAVORITES LIST, ACCEPTS TYPE IN PAYLOAD
  async retrieveFavorites ({ commit, dispatch, getters }, payload) {
    const recordId = getters.getUserRecordId
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    projectBase('User Accounts').find(recordId, function (err, record) {
      if (err) { console.error(err); return }
      let favoriteQuotes = record?.get('favoriteQuotes')
      let favoriteStories = record?.get('favoriteStories')
      let favoriteImages = record?.get('favoriteImages')
      switch (payload) {
        case 'quotes':
          if (favoriteQuotes === undefined) {
            favoriteQuotes = []
          }
          commit('SET_FAVORITE_QUOTES', favoriteQuotes)
          break
        case 'stories':
          if (favoriteStories === undefined) {
            favoriteStories = []
          }
          commit('SET_FAVORITE_STORIES', favoriteStories)
          break
        case 'images':
          if (favoriteImages === undefined) {
            favoriteImages = []
          }
          commit('SET_FAVORITE_IMAGES', favoriteImages)
          break
      }
      commit('SET_FAVORITES_LOADED', true)
    })
  },
  // SET USER ACCEPTED TERMS AND CONDITIONS
  acceptTerms ({ commit }) {
    commit('SET_USER_TERMS_ACCEPTED', true)
  },
  // NULLIFIES STATES RELATED TO USER STATE
  setUserLoggedOut ({ commit }) {
    // console.log('setting user logged out')
    commit('SET_USER_LOGGED_IN', false)
    commit('SET_USER_EMAIL', null)
    commit('SET_USER_AUTHENTICATED', null)
    commit('SET_USER_IS_ADMIN', null)
    commit('SET_USER_RECORD_ID', null)
  },
  // SET SORT CONDITIONS
  setSortBy ({ commit }, payload) {
    commit('SET_SORT_BY', payload)
  }
}
