import { GetterTree } from 'vuex'
import { TagsState } from './types'
import { RootState } from '../types'

export const getters: GetterTree<TagsState, RootState> = {
  getTagsQueries (state): Array<string> {
    return state.queries
  },
  getTagsQueriesLoaded (state): boolean {
    return state.queriesLoaded
  },
  getTagsQueriesMessage (state): string | null {
    return state.queriesMessage
  }
}
