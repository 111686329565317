import { ActionTree } from 'vuex'
import { RootState } from '../types'
import { QuoteState } from './types'
import Airtable from 'airtable'
const base = new Airtable({
  apiKey: process.env.VUE_APP_AIRTABLE_API_KEY
})
const view = 'Quotes'

const fieldsConfig = [
  'type',
  'quoteId',
  'body',
  'quoteTitle',
  'date',
  'linkedTags',
  'linkedTagsNames',
  'linkedParticipants',
  'linkedParticipantsNames',
  'linkedStories',
  'linkedStoriesTitles',
  'programsName',
  'createdDate'
]

const selectConfig = {
  view: view,
  fields: fieldsConfig
}

const queryConfig = {
  view: view,
  fields: ['body']
}

export const actions: ActionTree<QuoteState, RootState> = {
  async loadQuotesData ({ commit, dispatch }, selectObj) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase(view).select(selectObj).eachPage(function page (records, fetchNextPage: () => void) {
      const filertedRecords = records.map(function (record) {
        return record.fields
      })
      if (selectObj.filterByFormula) {
        commit('SET_QUOTES_QUERY_DATA', filertedRecords)
        commit('SET_QUOTES_QUERY_DATA_LOADED', true)
      } else {
        commit('SET_QUOTES_DATA', filertedRecords)
        commit('SET_QUOTES_LOADED', true)
      }
      fetchNextPage()
    }, function done (err: string) {
      if (err) {
        commit('SET_QUOTES_LOADED', false)
        commit('SET_QUOTES_MESSAGE', err)
      }
    })
  },
  getQuotesData ({ dispatch }, requestObj) {
    let selectObj: Record<string, unknown>
    switch (requestObj.type) {
      case 'retrieveAll':
        selectObj = selectConfig
        dispatch('loadQuotesData', selectObj)
        break
      case 'filterBy':
        selectObj = {
          view: view,
          fields: fieldsConfig,
          filterByFormula: requestObj.query
        }
        dispatch('loadQuotesData', selectObj)
        break
    }
  },
  setQuotesLoadedState ({ commit }, newState) {
    commit('SET_QUOTES_LOADED', newState)
  },
  clearQuotesData ({ commit }) {
    commit('SET_QUOTES_QUERY_DATA', [])
    commit('SET_QUOTES_QUERY_DATA_LOADED', false)
  },
  setQuotesQueriesLoadedState ({ commit }, newState) {
    commit('SET_QUOTES_QUERY_DATA_LOADED', newState)
  },
  async loadQuotesQueries ({ commit, dispatch }) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase(view).select(queryConfig).eachPage(function page (records, fetchNextPage: () => void) {
      records.map(function (record) {
        if (record.fields.body) {
          commit('SET_QUOTES_QUERIES', record.fields.body)
        }
      })
      commit('SET_QUOTES_QUERIES_LOADED', true)
      fetchNextPage()
    }, function done (err: string) {
      if (err) {
        commit('SET_QUOTES_QUERIES_MESSAGE', err)
      }
    })
  }
}
