
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ArrayJoin',
  props: {
    namesArray: Array,
    joinedProp: String,
    display: String
  },
  computed: {
    linkTarget: function () {
      if (this.joinedProp === 'Stories') {
        return 'Story'
      } else {
        return 'Search'
      }
    },
    nameArray: function () {
      return this.namesArray as Array<string>
    },
    currentFilter: function () {
      if (this.$route.name === 'Story' || this.$route.name === 'Home') {
        return ''
      } else {
        return this.$route.params.filter
      }
    }
  },
  methods: {
    pluralizedName: function (length:number, value:string): string {
      if (length === 1) {
        if (value === 'Stories') {
          return 'Story' as string
        }
        return value.slice(0, -1) as string
      } else {
        return value
      }
    },
    onClick: function (i:number) {
      let paramsObj = {}
      if (this.joinedProp === 'Stories') {
        paramsObj = { storyTitle: this.nameArray[i] }
        this.$router.push({ name: this.linkTarget, params: paramsObj })
      } if (this.joinedProp === 'Participants') {
        const target = `#${this.nameArray[i].replace(/ /g, '')}`
        this.$router.push({ name: 'Participants', hash: target })
      } else {
        paramsObj = { query: this.nameArray[i], filter: this.currentFilter }
        this.$router.push({ name: this.linkTarget, params: paramsObj })
      }
    }
  }
})
