import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { PagesState } from './types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

const state: PagesState = {
  feedData: [],
  feedLoaded: false,
  feedMessage: null,
  infoData: [],
  infoLoaded: false,
  infoMessage: null
}

export const pages: Module<PagesState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
