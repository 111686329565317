
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import SearchForm from '@/components/SearchForm.vue'
import vClickOutside from 'click-outside-vue3'

export default defineComponent({
  name: 'FilterNav',
  components: {
    SearchForm
  },
  props: {
    queried: Boolean,
    showSearchForm: Boolean,
    target: String
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      searchActive: false,
      favoritesActive: false,
      sortActive: false
    }
  },
  computed: {
    ...mapGetters({
      participants: 'getParticipantsData',
      images: 'getImagesData',
      quotes: 'getQuotesQueryData',
      stories: 'getStoriesQueryData',
      sortBy: 'getSortBy'
    })
  },
  mounted () {
    this.$store.dispatch('setSortBy', 'Most Recent')
  },
  methods: {
    onClickOutside () {
      this.sortActive = false
    },
    toggleSort: function () {
      this.sortActive = !this.sortActive
    },
    updateSort: function (sort:string) {
      this.$store.dispatch('setSortBy', sort)
    },
    paramsObj (type : {name: string | undefined, filter: string}) {
      if (type.name === 'Search') {
        return {
          name: 'Search',
          params: {
            slug: this.$route.params.slug,
            query: this.$route.params.query,
            filter: type.filter
          }
        }
      } if (type.name === 'Favorites') {
        return {
          name: 'Favorites',
          params: {
            slug: this.$route.params.slug,
            type: type.filter
          }
        }
      }
    },
    isActive (type:string) {
      if (this.$route.params.filter) {
        return this.$route.params.filter.toString().search(type) >= 0
      } else {
        return false
      }
    }
  }
})
