
import { defineComponent, ref, watch, computed, onMounted } from 'vue'
import { userSignedIn, userSignOut, userData } from '@/firebase'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'AccountControls',
  setup (): any {
    const router = useRouter()
    const store = useStore()
    const drawerOpen = ref(false)
    const userAuthenticated = computed(() => store.getters.getUserAuthenticated)
    const signOut = () => {
      console.log('logging out')
      userSignOut()
      store.dispatch('setUserLoggedOut')
    }
    const toggleDrawer = () => {
      drawerOpen.value = !drawerOpen.value
    }
    const authenticateUser = async () => {
      const loginData = await userData
      // console.log('LOGIN DATA', loginData.value.email)
      store.dispatch('setUserLoggedIn', loginData.value)
      store.dispatch('findUserRecordId', loginData.value.email)
    }
    onMounted(() => {
      if (!userSignedIn.value) {
        window.location.reload()
        router.push('/login')
      } if (!userAuthenticated.value) {
        authenticateUser()
      }
    })
    watch(userSignedIn, (newValue) => {
      if (!newValue) {
        window.location.reload()
        router.push('/login')
      }
    })
    watch(userAuthenticated, (newValue) => {
      if (!newValue) {
        authenticateUser()
      }
    })

    return { signOut, userAuthenticated, userSignedIn, drawerOpen, toggleDrawer }
  }
})
