import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { QuoteState } from './types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

const state: QuoteState = {
  queryData: [],
  queryDataLoaded: false,
  queries: [],
  queriesLoaded: false,
  queriesMessage: null
}

export const quotes: Module<QuoteState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
