import { ActionTree } from 'vuex'
import { RootState } from '../types'
import { PagesState } from './types'
import Airtable from 'airtable'

const base = new Airtable({
  apiKey: process.env.VUE_APP_AIRTABLE_API_KEY
})

const InfoPagesConfig = [
  'title',
  'bodyText'
]

const InfoPagesSelectObj = {
  view: 'Infopages',
  fields: InfoPagesConfig
}

const FeedFieldsConfig = [
  'type',
  'storyId',
  'storyTitle',
  'storyLede',
  'storyParticipants',
  'storyProgramsName',
  'storyTags',
  'quoteId',
  'quoteText',
  'quoteParticipants',
  'quoteProgramsName',
  'quoteTags',
  'imageId',
  'imageTitle',
  'imageAttachments',
  'imageStories',
  'imageParticipants',
  'imageProgramsName',
  'imageTags'
]

const feedSelectObj = {
  view: 'Homepage',
  fields: FeedFieldsConfig
}

export const actions: ActionTree<PagesState, RootState> = {
  async loadFeedData ({ commit, dispatch }) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase('Homepage').select(feedSelectObj).eachPage(function page (records, fetchNextPage: () => void) {
      const filertedRecords = records.map(function (record) {
        return record.fields
      })
      commit('SET_FEED_DATA', filertedRecords)
      commit('SET_FEED_LOADED', true)
      fetchNextPage()
    }, function done (err: string) {
      if (err) {
        commit('SET_FEED_LOADED', false)
        commit('SET_FEED_MESSAGE', err)
      }
    })
  },
  async loadInfoPageData ({ commit, dispatch }, selectObj) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase('InfoPages').select(selectObj).eachPage(function page (records, fetchNextPage: () => void) {
      const filertedRecords = records.map(function (record) {
        return record.fields
      })
      commit('SET_INFO_DATA', filertedRecords)
      commit('SET_INFO_LOADED', true)
      fetchNextPage()
    }, function done (err: string) {
      if (err) {
        commit('SET_INFO_LOADED', false)
        commit('SET_INFO_MESSAGE', err)
      }
    })
  },
  getInfoPagesData ({ dispatch }) {
    dispatch('loadInfoPageData', InfoPagesSelectObj)
  }
}
