import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { TagsState } from './types'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

const state: TagsState = {
  queries: [],
  queriesLoaded: false,
  queriesMessage: null
}

export const tags: Module<TagsState, RootState> = {
  state,
  getters,
  mutations,
  actions
}
