import { MutationTree } from 'vuex'
import { TagsState } from './types'

export enum TagsMutations {
  SET_TAGS_DATA = 'SET_TAGS_DATA',
  SET_TAGS_LOADED = 'SET_TAGS_LOADED',
  SET_TAGS_MESSAGE = 'SET_TAGS_MESSAGE',
  SET_TAGS_QUERIES = 'SET_TAGS_QUERIES',
  SET_TAGS_QUERIES_LOADED = 'SET_TAGS_QUERIES_LOADED',
  SET_TAGS_QUERIES_MESSAGE = 'SET_TAGS_QUERIES_MESSAGE',
}

export const mutations: MutationTree<TagsState> = {
  [TagsMutations.SET_TAGS_QUERIES] (state, payload: string) {
    state.queries.push(payload)
  },
  [TagsMutations.SET_TAGS_QUERIES_LOADED] (state, payload: boolean) {
    state.queriesLoaded = payload
  },
  [TagsMutations.SET_TAGS_QUERIES_MESSAGE] (state, payload: string) {
    state.queriesMessage = payload
  }
}
