
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'Home',
  components: {
    Nav,
    Footer
  },
  data () {
    return {
      isPublic: true
    }
  },
  computed: {
    // Get base data from store
    ...mapGetters({
      baseId: 'getBaseId',
      baseSlug: 'getBaseSlug',
      baseStatus: 'getBaseStatus'
    })
  },
  mounted () {
    // Reset Base Status
    // this.$store.dispatch('setBaseStatus', null)
    // Computes if page is public, to control which components are rendered
    this.checkPublic()
  },
  watch: {
    // If route slug changes, match against api for respective project
    $route (to, from) {
      if (to.params.slug !== from.params.slug) {
        this.checkSlug()
      }
      this.checkPublic()
    },
    // If base status is set to 404 due to a connection error, redirect to 404 page
    baseStatus (to) {
      if (to === 404) {
        // console.log('status is 404')
        this.$router.push('/not-found')
      }
    }
  },
  methods: {
    checkPublic () {
      if (this.$route.meta.public === true || this.$route.meta.public === false) {
        this.isPublic = this.$route.meta.public
      }
    },
    // Connect to api to check for current slug in projects
    checkSlug () {
      // console.log('checking slug')
      this.loadBaseData()
      this.$store.dispatch('setBaseSlug', this.$route.params.slug)
    },
    // If baseId has not been set, connect to project api with current slug
    loadBaseData () {
      // console.log('loading base data')
      if (this.baseId === null) {
        this.$store.dispatch('connectToBase', this.$route.params.slug)
      }
    }
  }
})
