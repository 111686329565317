import { ActionTree } from 'vuex'
import { RootState } from '../types'
import { ImageState } from './types'
import Airtable from 'airtable'

const base = new Airtable({
  apiKey: process.env.VUE_APP_AIRTABLE_API_KEY
})

const view = 'Images'

const fieldsConfig = [
  'type',
  'imageId',
  'description',
  'attachments',
  'linkedParticipants',
  'linkedStories',
  'linkedParticipantsNames',
  'linkedStoriesTitles',
  'linkedTags',
  'favoritedUserList',
  'programsName',
  'createdDate'
]

const selectConfig = {
  view: view,
  fields: fieldsConfig
}

const queryConfig = {
  view: view,
  fields: ['description']
}
export const actions: ActionTree<ImageState, RootState> = {
  async loadImagesData ({ commit, dispatch }, selectObj) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase(view).select(selectObj).eachPage(function page (records, fetchNextPage: () => void) {
      const filertedRecords = records.map(function (record) {
        return record.fields
      })
      commit('SET_IMAGES_DATA', filertedRecords)
      commit('SET_IMAGES_LOADED', true)
      fetchNextPage()
    }, function done (err: string) {
      if (err) {
        commit('SET_IMAGES_LOADED', false)
        commit('SET_IMAGES_MESSAGE', err)
      }
    })
  },
  getImagesData ({ dispatch }, requestObj) {
    let selectObj: Record<string, unknown>
    switch (requestObj.type) {
      case 'retrieveAll':
        selectObj = selectConfig
        dispatch('loadImagesData', selectObj)
        break
      case 'filterBy':
        selectObj = {
          view: view,
          fields: fieldsConfig,
          filterByFormula: requestObj.query
        }
        dispatch('loadImagesData', selectObj)
        break
    }
  },
  clearImagesData ({ commit }) {
    commit('SET_IMAGES_DATA', [])
    commit('SET_IMAGES_LOADED', false)
  },
  async loadImagesQueries ({ commit, dispatch }) {
    const baseId = await dispatch('checkBase')
    const projectBase = base.base(baseId)
    return projectBase(view).select(queryConfig).eachPage(function page (records, fetchNextPage: () => void) {
      records.map(function (record) {
        if (record.fields.name) {
          commit('SET_IMAGES_QUERIES', record.fields.name)
        }
      })
      commit('SET_IMAGES_QUERIES_LOADED', true)
      fetchNextPage()
    }, function done (err: string) {
      if (err) {
        commit('SET_IMAGES_QUERIES_MESSAGE', err)
      }
    })
  },
  setImagesLoadedState ({ commit }, payload) {
    commit('SET_IMAGES_LOADED', payload)
  }
}
